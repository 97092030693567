import powertext from "./powertext";

export default {
	state: {
		powersuiteApps: [],
		
	},
	modules: {
		powerText: powertext,
	},
	mutations: {
		setPowersuiteApps(state, powersuiteApps) {
			state.powersuiteApps = powersuiteApps;
		},
	},
	actions: {
		updatePowersuiteApps({ commit }, powersuiteApps) {
			commit("setPowersuiteApps", powersuiteApps);
		},
	},
	getters: {
		getPowersuiteApps(state) {
			return state.powersuiteApps;
		},
	}
};
