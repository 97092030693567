import axios from "axios";
import Vue from "vue";

export const baseURL = import.meta.env.VITE_BACKEND_URL || window.location.origin;

const api = axios.create({
	baseURL
});

api.interceptors.request.use(config => {
	const jwt = Vue.$cookies.get(import.meta.env.VITE_AUTH_TOKEN_ID);
	if ((jwt !== null || jwt !== undefined) && (!config.headers.Authorization)) {
		config.headers.Authorization = `Bearer ${jwt}`;
	}
	return config;
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			window.location.href = error.response.data.redirect;
		} else return Promise.reject(error);
	}
);

export default api;
