
<v-dialog max-width="50%" v-model="helpStatus.open">
	<v-card max-height="50vh">
		<v-app-bar flat color="primary" dark>
			<v-toolbar-title>PowerText Help</v-toolbar-title>
		</v-app-bar>

		<v-card-title>
			{{ page.title }}
		</v-card-title>

		<v-card-text>
			<div v-html="page.html"></div>
		</v-card-text>

		<v-footer class="pa-2 justify-end">
			<v-btn text @click="closeHelp">Close</v-btn>
		</v-footer>
	</v-card>
</v-dialog>
