import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/pages/Home/App.vue")
	},
	{
		path: "/home",
		name: "Home2",
		component: () => import("@/pages/Home/App.vue")
	},
	{
		path: "/upload/:id",
		name: "UploadDetail",
		component: () => import("@/pages/Upload/View.vue")
	},
	{
		path: "/inspect",
		name: "Inspect",
		component: () => import("@/pages/Inspect/App.vue")
	},
	{
		path: "/upload",
		name: "Upload",
		component: () => import("@/pages/Upload/Upload.vue")
	},
	// Category
	{
		path: "/category/inspect",
		name: "CategoryInspect",
		component: () => import("@/pages/Category/Inspect/View.vue")
	},
	{
		path: "/category/trainingdata",
		name: "CategoryTrainingdata",
		component: () => import("@/pages/Category/TrainingData/View.vue")
	},
	// Paraphrase
	{
		path: "/paraphrase/inspect",
		name: "ParaphraseInspect",
		component: () => import("@/pages/Paraphrase/Inspect/View.vue")
	},

	{
		path: "/api/settings",
		name: "ApiSettings",
		component: () => import("@/pages/Api/Settings/View.vue")
	},
	{
		path: "/trainingdata",
		name: "Trainingdata",
		component: () => import("@/pages/TrainingData/App.vue")
	},
	{
		path: "/dictionaries",
		name: "Dictionaries",
		component: () => import("@/pages/Dictionary/App.vue")
	},
	{
		path: "/dictionaries/replace/:id",
		name: "Replace dictionary details",
		component: () =>
			import(
				"@/components/ReplaceDictionaries/ReplaceDictionaryDetails.vue"
			)
	},
	{
		path: "/dictionaries/synonym/:id",
		name: "Synonym dictionary details",
		component: () =>
			import(
				"@/components/SynonymDictionaries/SynonymDictionaryDetails.vue"
			)
	},
	{
		path: "/dictionaries/language/:id",
		name: "Language dictionary details",
		component: () =>
			import(
				"@/components/LanguageDictionaries/LanguageDictionaryDetails.vue"
			)
	},
	{
		path: "/edit/:id/v/:version",
		name: "Edit",
		component: () => import("@/pages/Editcopy/App.vue")
	},
	{
		path: "/translations",
		name: "Translations",
		component: () => import("@/pages/Translations/View.vue")
	},
	{
		path: "/translations/datasets/:dataset_id/edit",
		name: "Editor",
		component: () => import("@/pages/Translations/Edit.vue")
	},
		{
		path: "/summarize/inspect",
		name: "SummarizeInspect",
		component: () => import("@/pages/Summarize/Inspect/View.vue")
	},
		{
		path: "/generative-text/inspect",
		name: "GenerativeTextsInspect",
		component: () => import("@/pages/GenerativeTexts/Inspect/View.vue")
	},
];

const router = new VueRouter({
	mode: "history",
	routes
});

export default router;
