import axios from "@/axios";

export default {
	state: {
		categories: [],
		selectedCategory: null,
		loadingCategories: true,
	},
	mutations: {
		powerTranslateSetCategories(state, categories) {
			state.categories = categories;
		},
		powerTranslateAddCategory(state, category) {
			state.categories.push(category);
		},
		powerTranslateSelectCategory(state, category) {
			state.selectedCategory = category;
		},
		powerTranslateSetCategoriesLoading(state, isLoading) {
			state.loadingCategories = isLoading
		}
	},
	actions: {
		powerTranslateSelectCategory({ commit }, category) {
			commit("powerTranslateSelectCategory", category);
		},
		powerTranslateGetCategories({ commit }) {
			commit("powerTranslateSetCategoriesLoading", true);
			return new Promise((resolve, reject) => {
				axios
					.get('/api/service/translations/categories')
					.then(response => {
						commit("powerTranslateSetCategories", response.data)
					})
					.catch(_ => {
						reject();
					})
					.finally(() => {
						commit("powerTranslateSetCategoriesLoading", false);
						resolve();
					})
			});
		},
		powerTranslateCreateCategory(ctx, name) {
			const data = { 'name': name };
			return new Promise((resolve, reject) => {
				axios
					.post('/api/service/translations/categories', data)
					.then(_ => {
						resolve();
					})
					.catch(_ => {
						reject();
					})
			});
		},
		powerTranslateChangeParent({ commit }, { category, parent }) {
			commit("powerTranslateSetCategoriesLoading", true);
			const data = { 'category': category.id, 'parent': parent.id, };
			return new Promise((resolve, reject) => {
				axios
					.post('/api/service/translations/categories/parent', data)
					.then(_ => {
						resolve();
					})
					.catch(_ => {
						reject();
					})
					.finally(() => {
						commit("powerTranslateSetCategoriesLoading", false);
					})
			});
		},
		powerTranslateTranslateDataset(ctx, dataset_id) {
			return axios.post(`/api/service/translations/dataset/${dataset_id}/translate_all`, {});
		}
	},
	getters: {
		powerTranslateCategories(state) {
			return state.categories;
		},
		powerTranslateSelectedCategory(state) {
			return state.selectedCategory;
		},
		powerTranslateIsLoadingCategories(state) {
			return state.isLoadingCategories;
		}
	}
}
