import axios from "../../../axios";

export default {
	state: {
		languageDictionaries: [],
		selectedLanguageDictionary: {},
		selectedLanguageDictionaryElements: []
	},
	mutations: {
		setLanguageDictionaries(state, languageDictionaries) {
			state.languageDictionaries = languageDictionaries;
		},
		addLanguageDictionary(state, languageDictionary) {
			state.languageDictionaries.push(languageDictionary);
		},
		deleteLanguageDictionary(state, id) {
			state.languageDictionaries = state.languageDictionaries.filter(
				item => item.id !== id
			);
		},
		updateLanguageDictionary(state, element) {
			state.languageDictionaries = state.languageDictionaries.map(
				item => {
					if (item.id !== element.id) return item;
					else return element;
				}
			);
		},
		setSelectedLanguageDictionary(state, dict) {
			state.selectedLanguageDictionary = dict;
		},
		addLanguageDictionaryElement(state, element) {
			state.selectedLanguageDictionaryElements.push(element);
		},
		setLanguageDictionaryElement(state, elements) {
			state.selectedLanguageDictionaryElements = elements;
		},
		deleteLanguageDictionaryElement(state, id) {
			state.selectedLanguageDictionaryElements = state.selectedLanguageDictionaryElements.filter(
				item => item.id !== id
			);
		},
		updateSelectedDictionaryElement(state, element) {
			state.selectedLanguageDictionaryElements = state.selectedLanguageDictionaryElements.map(
				item => {
					if (item.id !== element.id) return item;
					else if (item.id === element.id) return element;
				}
			);
		}
	},
	actions: {
		saveLanguageDictionary({}, dictionaryId) {
			return axios.post(`/api/language_dictionary/${dictionaryId}/save`);
		},
		getLanguageDictionaries({ commit }) {
			return axios.get("/api/language_dictionary").then(response => {
				commit("setLanguageDictionaries", response.data);
			});
		},
		addLanguageDictionary(
			{ commit },
			{ dictName, fromLanguage, toLanguage }
		) {
			return axios
				.post("/api/language_dictionary", {
					name: dictName,
					fromLanguage,
					toLanguage
				})
				.then(response => {
					commit("addLanguageDictionary", response.data);
				});
		},
		deleteLanguageDictionary({ commit }, id) {
			return axios
				.delete(`/api/language_dictionary/${id}`)
				.then(response => {
					commit("deleteLanguageDictionary", response.data.id);
				});
		},
		updateLanguageDictionary({ commit }, { id, data }) {
			return axios
				.put(`/api/language_dictionary/${id}`, data)
				.then(() => {
					commit("updateLanguageDictionary", data);
				});
		},
		selectLanguageDictionary({ commit }, id) {
			return axios
				.get(`/api/language_dictionary/${id}?query=elements`)
				.then(response => {
					commit(
						"setLanguageDictionaryElement",
						response.data.elements
					);
					commit("setSelectedLanguageDictionary", response.data);
				});
		},
		addLanguageDictionaryElement({ commit, state }, element) {
			return axios
				.post(
					`/api/language_dictionary/${state.selectedLanguageDictionary.id}/element`,
					element
				)
				.then(response => {
					commit("addLanguageDictionaryElement", response.data);
				});
		},
		deleteLanguageDictionaryElement({ commit, state }, id) {
			return axios
				.delete(
					`/api/language_dictionary/${state.selectedLanguageDictionary.id}/element/${id}`
				)
				.then(({ data }) => {
					commit("deleteLanguageDictionaryElement", data.id);
				});
		},
		updatLanguageDictionaryElement({ commit, state }, element) {
			return axios
				.put(
					`/api/language_dictionary/${state.selectedLanguageDictionary.id}/element/${element.id}`,
					element
				)
				.then(({ data }) => {
					commit("updateSelectedDictionaryElement", element);
				});
		},
		uploadLanguageDictionaryElements({ commit, state }, file) {
			let formData = new FormData();
			formData.append("file", file);
			axios
				.post(
					`/api/language_dictionary/${state.selectedLanguageDictionary.id}/element`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}
				)
				.then(response => {
					response.data.forEach(element => {
						commit("addLanguageDictionaryElement", element);
					});
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	getters: {
		getLanguageDictionaries(state) {
			return state.languageDictionaries;
		},
		getSelectedLanguageDictionary(state) {
			return state.selectedLanguageDictionary;
		},
		getLanguageDictionaryElements(state) {
			return state.selectedLanguageDictionaryElements;
		}
	}
};
