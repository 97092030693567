export default {
	state: {
		previewModalVisible: false,
		lastFocussedField: null,
		draggingFeature: null,
		features: [],
		previewFocussedCondition: null,
		usedFeatures: {},
		config: {}
	},
	mutations: {
		setFeatureIdentifier(state, data) {
			for (let i = 0; i < state.features.length; i++) {
				if (state.features[i].key === data.id) {
					state.features[i].indentifier = data.checked;
				}
			}
		},
		setFeatures(state, features) {
			// const sorted = features.sort((a, b) => {
			//     const nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
			//     if (nameA === nameB) return 0;
			//     return nameA < nameB ? -1 : 1;
			// });
			// state.features = sorted;
			state.features = features;
		},
		setUsedFeatures(state, data) {
			state.usedFeatures[data.id] = data.features;
		},
		setDraggingFeature(state, feature) {
			state.draggingFeature = feature;
		},
		setFocussedField(state, field) {
			state.lastFocussedField = field;
		},
		setPreviewFocussedCondition(state, data) {
			state.previewFocussedCondition = data;
		},
		togglePreviewModal(state, bool) {
			state.previewModalVisible = bool;
		},
		setClassData(state, data) {
			Object.assign(state.classData, data);
		},
		setConfig(state, data) {
			state.config = data;
		}
	},
	getters: {
		previewModalVisible: state => state.previewModalVisible,
		lastFocussedField: state => state.lastFocussedField,
		previewFocussedCondition: state => state.previewFocussedCondition,
		draggingFeature: state => state.draggingFeature,
		features: state => state.features,
		feature: state => id => state.features.find(f => f.key === id),
		usedFeatures: state => id => state.usedUniqueFeatures[id],
		uniqueUsedFeatures: state => () => {
			const keys = Object.keys(state.usedFeatures);
			const uniqueFeatures = [];
			for (let i = 0; i < keys.length; i++) {
				for (let j = 0; j < state.usedFeatures[keys[i]].length; j++) {
					const feature = state.usedFeatures[keys[i]][j];
					if (
						!uniqueFeatures.filter(f => f.id === feature.id).length
					) {
						uniqueFeatures.push(feature);
					}
				}
			}
			return uniqueFeatures;
		},
		getConfig: state => state.config
	}
};
