import axios from "@/axios";

export default {
	state: {
        replacementDictionaryElements: [],
		selectdReplacementDictionaryElementId: null,
	},
	mutations: {
		setReplacementDictionaryElements(state, replacementDictionaryElements) {
			state.replacementDictionaryElements = replacementDictionaryElements;
		},
		selectReplacementDictionaryElement(state, replacementDictionaryElementId) {
			state.selectdReplacementDictionaryElementId = replacementDictionaryElementId;
		},
	},
	actions: {
		getReplacementDictionaryElements({ commit, getters }, replacementDictionaryId) {
            axios
				.get(`/api/replace_dictionary/` + replacementDictionaryId + `/element`)
				.then(response => {
					commit("setReplacementDictionaryElements", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
        addReplacementDictionaryElement({ commit, getters }, { replaceFrom, replaceTo, matchCase }) {
            axios
				.post(`/api/replace_dictionary/` + getters.selectedReplacementDictionary.id + `/element`,
                    {
						replace_from: replaceFrom,
						replace_to: replaceTo,
						case_sensitive: matchCase,
					}
                )
				.then(response => {
					commit("setReplacementDictionaryElements", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		uploadReplacementDictionaryElements({ commit, getters }, uploadFile) {
			let formData = new FormData();
      		formData.append('upload_file', uploadFile);
            axios
				.post(`/api/replace_dictionary/` + getters.selectedReplacementDictionary.id + `/element`,
                    formData, 
					{
						headers: {
						  'Content-Type': 'multipart/form-data'
						}
					}
                )
				.then(response => {
					commit("setReplacementDictionaryElements", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		editReplacementDictionaryElement({ commit, getters }, { replacementDictionaryElementId, replaceFrom, replaceTo, matchCase }) {
            axios
				.patch(`/api/replace_dictionary/` + getters.selectedReplacementDictionary.id + `/element/` + replacementDictionaryElementId,
				{
					replace_from: replaceFrom,
					replace_to: replaceTo,
					case_sensitive: matchCase,
				}
                )
				.then(response => {
					commit("setReplacementDictionaryElements", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		deleteReplacementDictionaryElement({ commit, getters }, replacementDictionaryElementId) {
            axios
				.delete(`/api/replace_dictionary/` + getters.selectedReplacementDictionary.id + `/element/` + replacementDictionaryElementId)
				.then(response => {
					commit("setReplacementDictionaryElements", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		selectReplacementDictionaryElement({ commit }, replacementDictionaryElementId) {
			commit("selectdReplacementDictionaryElementId", replacementDictionaryElementId);
		},
	},
	getters: {
		replacementDictionaryElements(state) {
			return state.replacementDictionaryElements;
		},
		selectdReplacementDictionaryElement(state) {
			return state.replacementDictionaryElements.find(d => d.id === state.selectdReplacementDictionaryElementId);
		},
	}
};
