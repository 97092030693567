<template>
	<v-app class="layout">
		<Navigation />
		<v-main :style="{ 'background-color': `white` }">
			<v-container class="page-wrapper">
				<router-view />
			</v-container>
		</v-main>
		<Footer />
		<Help />
	</v-app>
</template>

<script>
import Navigation from "./components/Layout/Navigation.vue";
import Footer from "./components/Layout/Footer.vue";
import Help from "./components/help/Help.vue"

export default {
	name: "Layout",
	components: {
		Navigation,
		Footer,
		Help,
	}
};
</script>

<style lang="scss">
.page-wrapper {
	padding: 20px 20px 20px 20px;
	min-height: 100%;
}

.layout {
	height: 100%;
}

.container {
	max-width: unset !important;
}
</style>
