import axios from "@/axios";
import replace_dictionary_element_store from "./replace_dictionary_element_store";

export default {
	modules: {
		replace_dictionary_element_store
	},
	state: {
        replacementDictionaries: [],
		selectdReplacementDictionaryId: null,
	},
	mutations: {
		setReplacementDictionaries(state, replacementDictionaries) {
			state.replacementDictionaries = replacementDictionaries;
		},
		selectReplacementDictionary(state, dictionaryId) {
			state.selectdReplacementDictionaryId = dictionaryId;
		},
	},
	actions: {
		getReplacementDictionaries({ commit }) {
            axios
				.get(`/api/replace_dictionary`)
				.then(response => {
					commit("setReplacementDictionaries", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
        addReplacementDictionary({ commit }, dictionaryName) {
            axios
				.post(`/api/replace_dictionary`,
                    {name: dictionaryName}
                )
				.then(response => {
					commit("setReplacementDictionaries", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		editReplacementDictionary({ commit }, {dictionaryId, dictionaryName }) {
            axios
				.patch(`/api/replace_dictionary/` + dictionaryId,
                    {name: dictionaryName}
                )
				.then(response => {
					commit("setReplacementDictionaries", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		deleteReplacementDictionary({ commit }, dictionaryId) {
            axios
				.delete(`/api/replace_dictionary/` + dictionaryId)
				.then(response => {
					commit("setReplacementDictionaries", response.data);
				})
				.catch(error => {
					console.log(error);
				})
        },
		selectReplacementDictionary({ commit }, dictionaryId) {
			commit("selectReplacementDictionary", dictionaryId);
		},
	},
	getters: {
		replacementDictionaries(state) {
			return state.replacementDictionaries;
		},
		selectedReplacementDictionary(state) {
			return state.replacementDictionaries.find(d => d.id == state.selectdReplacementDictionaryId);
		},
	}
};
