import help from "@/components/help/store/help";
import replaceDictionaryStore from "@/components/ReplaceDictionaries/store/replace_dictionary_store";
import PowerDataStore from "@/components/PowerData/store/PowerDataStore";
import categories from "@/components/categories/store/categories";
import languageDictionaryStore from "@/components/LanguageDictionaries/store/languageDictionaryStore";;
import synonymDictionaryStore from "@/components/SynonymDictionaries/store/synonymDictionaryStore";
import powertranslate from "@/store/powertranslate"
import powersummarize from "@/store/powersummarize"

export default {
	state: {},
	modules: {
		help,
		replaceDictionaryStore,
		categories,
		PowerDataStore,
		synonymDictionaryStore,
		languageDictionaryStore,
		powertranslate,
		powersummarize
	}
};
