import axios from "../../../axios";

export default {
	state: {
		synonymDictionaries: [],
		selectedSynonymDictionary: {},
		selectedSynonymDictionaryElements: []
	},
	mutations: {
		setSynonymDictionaries(state, synonymDictionaries) {
			state.synonymDictionaries = synonymDictionaries;
		},
		addSynonymDictionaries(state, synonymDictionary) {
			state.synonymDictionaries.push(synonymDictionary);
		},
		deleteSynonymDictionary(state, id) {
			state.synonymDictionaries = state.synonymDictionaries.filter(
				item => item.id !== id
			);
		},
		updateSynonymDictionary(state, element) {
			state.synonymDictionaries = state.synonymDictionaries.map(item => {
				if (item.id !== element.id) return item;
				else return element;
			});
		},
		setSelectedSynonymDictionary(state, dict) {
			state.selectedSynonymDictionary = dict;
		},
		addSynonymDictionaryElement(state, element) {
			state.selectedSynonymDictionaryElements.push(element);
		},
		setSynonymDictionaryElement(state, elements) {
			state.selectedSynonymDictionaryElements = elements;
		},
		deleteSynonymDictionaryElement(state, id) {
			state.selectedSynonymDictionaryElements = state.selectedSynonymDictionaryElements.filter(
				item => item.id !== id
			);
		},
		updateSelectedDictionaryElement(state, element) {
			state.selectedSynonymDictionaryElements = state.selectedSynonymDictionaryElements.map(
				item => {
					if (item.id !== element.id) return item;
					else if (item.id === element.id) return element;
				}
			);
		}
	},
	actions: {
		getSynonymDictionaries({ commit }) {
			return axios.get("/api/synonym_dictionary").then(response => {
				commit("setSynonymDictionaries", response.data);
			});
		},
		addSynonymDictionary({ commit }, dictName) {
			return axios
				.post("/api/synonym_dictionary", { name: dictName })
				.then(response => {
					commit("addSynonymDictionaries", response.data);
				});
		},
		deleteSynonymDictionary({ commit }, id) {
			return axios
				.delete(`/api/synonym_dictionary/${id}`)
				.then(response => {
					commit("deleteSynonymDictionary", response.data.id);
				});
		},
		updateSynonymDictionary({ commit }, { id, data }) {
			return axios.put(`/api/synonym_dictionary/${id}`, data).then(() => {
				commit("updateSynonymDictionary", data);
			});
		},
		selectSynonymDictionary({ commit }, id) {
			return axios
				.get(`/api/synonym_dictionary/${id}?query=elements`)
				.then(response => {
					commit(
						"setSynonymDictionaryElement",
						response.data.elements
					);
					commit("setSelectedSynonymDictionary", response.data);
				});
		},
		addSynonymtDictionaryElement({ commit, state }, element) {
			return axios
				.post(
					`/api/synonym_dictionary/${state.selectedSynonymDictionary.id}/element`,
					element
				)
				.then(response => {
					commit("addSynonymDictionaryElement", response.data);
				});
		},
		deleteSynonymDictionaryElement({ commit, state }, id) {
			return axios
				.delete(
					`/api/synonym_dictionary/${state.selectedSynonymDictionary.id}/element/${id}`
				)
				.then(({ data }) => {
					commit("deleteSynonymDictionaryElement", data.id);
				});
		},
		updateSynonymDictionaryElement({ commit, state }, element) {
			return axios
				.put(
					`/api/synonym_dictionary/${state.selectedSynonymDictionary.id}/element/${element.id}`,
					element
				)
				.then(({ data }) => {
					commit("updateSelectedDictionaryElement", element);
				});
		},
		uploadSynonymDictionaryElements({ commit, state }, file) {
			let formData = new FormData();
			formData.append("file", file);
			axios
				.post(
					`/api/synonym_dictionary/${state.selectedSynonymDictionary.id}/element`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}
				)
				.then(response => {
					response.data.forEach(element => {
						commit("addSynonymDictionaryElement", element);
					});
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	getters: {
		getSynonymDictionaries(state) {
			return state.synonymDictionaries;
		},
		getSelectedSynonymDictionary(state) {
			return state.selectedSynonymDictionary;
		},
		getSynonymDictionaryElements(state) {
			return state.selectedSynonymDictionaryElements;
		}
	}
};
