import axios from "@/axios";

export default {
	state: {
		categories: [],
		selectedCategory: null,
		loadingCategories: false,
	},
	mutations: {
		updateCategories(state, categories) {
			state.categories = categories;
		},
		selectCategory(state, category) {
			state.selectedCategory = category;
		},
		setLoadingCategories(state, isLoading) {
			state.loadingCategories = isLoading
		}
	},
	actions: {
		updateCategories({ commit }) {
			commit("setLoadingCategories", true)
            axios
				.get(`/api/view/hierarchy/simple`)
				.then(response => {
					commit("updateCategories", response.data);
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => commit("setLoadingCategories", false))
		},
		selectCategory({ commit }, category) {
			commit("selectCategory", category);
		},
		changeParent({ commit }, { category, parent }) {
			if (category.id != parent.id) {
				commit("setLoadingCategories", true)
				axios
					.post(`/api/services/hierarchy/parent`,
						{ hierarchy_id: category.id, parent_id: parent.id }
					)
					.then(response => {
						commit("updateCategories", response.data);
					})
					.catch(error => {
						console.log(error);
					})
					.finally(() => commit("setLoadingCategories", false))
			}
		}
	},
	getters: {
		categories(state) {
			return state.categories;
		},
		selectedCategory(state) {
			return state.selectedCategory;
		},
		isLoadingCategories(state) {
			return state.loadingCategories
		},
		getSelectedCategory(state) {
			return getCategoryById(state.categories, state.selectedCategory);
		},
	}
};

function getCategoryById(categories, id) {
	for (const category of categories) {
		if (category.id == id) {
			return category;
		}
		if (category.children.length == 0) {
			continue;
		}
		const child = getCategoryById(category.children, id);
		if (child) {
			return child;
		}
	}
	return null;
}