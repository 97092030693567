import axios from "@/axios";

export default {

	state: {
		generativeTextsDatasets: [],
		generativeTextsLanguages: [],
		generativeSettingProfiles: [],
		generativeDatamodels: [],
		generateConfiguratorDialogVisible: false,
		replaceDictionaries: [],
		selectedSettingProfile: null,
	},
	mutations: {
		setGenerateConfiguratorDialogVisible(state, visible) {
			state.generateConfiguratorDialogVisible = visible;
		},
		updateGenerativeTextsDatasets(state, datasets) {
			state.generativeTextsDatasets = datasets;
		},
		setGenerativeTextsLanguages(state, data) {
			state.generativeTextsLanguages = data;
		},
		setGenerativeTextsDatamodels(state, data) {
			state.generativeDatamodels = data;
		},
		setGenerativeSettingProfiles(state, data) {
			state.generativeSettingProfiles = data;
		},
		setReplacementDictionaries(state, data) {
			state.replaceDictionaries = data;
		},
		setSelectedSettingProfile(state, item) {
			state.selectedSettingProfile = item;
		}
	},
	actions: {
		getGenerativeTextsDatasets({commit}) {
			axios
				.get(`/api/generative-texts/datasets`)
				.then(response => {
					commit("updateGenerativeTextsDatasets", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getGenerativeTextsLanguages({commit}) {
			axios
				.get(`/api/generative-texts/languages`)
				.then(response => {
					commit("setGenerativeTextsLanguages", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getGenerativeTextsDatamodels({commit}) {
			axios
				.get(`/api/generative-texts/datamodels`)
				.then(response => {
					commit("setGenerativeTextsDatamodels", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getGenerativeSettingProfiles({commit}) {
			axios
				.get(`/api/generative-texts/settings`)
				.then(response => {
					commit("setGenerativeSettingProfiles", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
	},
};
