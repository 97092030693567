export default {
	state: {
        currentPage: 0,
        open: false,
	},
	mutations: {
		setHelpStatus(state, helpStatus) {
			state.currentPage = helpStatus.currentPage;
            state.open = helpStatus.open;
		}
	},
	actions: {
		openHelp({ commit, state }, page) {
			if(page == undefined){
				page = state.currentPage;
			}
			commit("setHelpStatus", {
				currentPage: page,
				open: true
			});
		},
		closeHelp({ commit, state }) {
			commit("setHelpStatus", {
				currentPage: state.currentPage,
				open: false
			});
		}
	},
	getters: {
		getHelpStatus(state) {
			return state;
		}
	}
};
