<template>
	<v-footer app color="footer" style="z-index: 10;">
		<v-row >
			<v-col class="text-right">
				<span class="white--text">&copy; Powertext | powered by Squadra</span>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	name: "Footer"
};
</script>

<style lang="scss">
</style>
