import Vue from "vue";
import Vuex from "vuex";
import TrainingData from "../pages/TrainingData/store";
import Editcopy from "../pages/Editcopy/store/store";
import user from "./user";
import powersuite from "./powersuite";
import Summarize from "./powersummarize";
import GenerativeTexts from "./generativetexts.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		TrainingData,
		user,
		Editcopy,
		powersuite,
		GenerativeTexts,
		Summarize,
	}
});
