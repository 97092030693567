import axios from "@/axios";

export default {

	state: {
		summarizeDatasets: [],
		languages: [],
		settingProfiles: [],
		generateConfiguratorDialogVisible: false,
		replaceDictionaries: [],
		selectedSettingProfile: null,
	},
	mutations: {
		setGenerateConfiguratorDialogVisible(state, visible) {
			state.generateConfiguratorDialogVisible = visible;
		},
		updateSummarizeDatasets(state, datasets) {
			state.summarizeDatasets = datasets;
		},
		setLanguages(state, data) {
			state.languages = data;
		},
		setSettingProfiles(state, data) {
			state.settingProfiles = data;
		},
		setReplacementDictionaries(state, data) {
			state.replaceDictionaries = data;
		},
		setSelectedSettingProfile(state, item) {
			state.selectedSettingProfile = item;
		}
	},
	actions: {
		getSummarizeDatasets({commit}) {
			axios
				.get(`/api/summarize/datasets`)
				.then(response => {
					commit("updateSummarizeDatasets", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getLanguageList({commit}) {
			axios
				.get(`/api/summarize/languages`)
				.then(response => {
					commit("setLanguages", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getSettingProfiles({commit}) {
			axios
				.get(`/api/summarize/settings`)
				.then(response => {
					commit("setSettingProfiles", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
		getReplacementDictionaries({commit}) {
			axios
				.get(`/api/replace_dictionary`)
				.then(response => {
					commit("setReplacementDictionaries", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
	},
};
