import axios from "@/axios";

export default {
	state: {
        powerDatasets: [],
		selectedDataset: null,
	},
	mutations: {
		updatePowerDatasets(state, datasets) {
			state.powerDatasets = datasets;
		},
	},
	actions: {
		getPowerDatasets({ commit }) {
            axios
				.get(`/api/powerdata/datasets`)
				.then(response => {
					commit("updatePowerDatasets", response.data);
				})
				.catch(error => {
					console.log(error);
				})
		},
	},
	getters: {
		powerDatasets(state) {
			return state.powerDatasets;
		},
		powerTrainingDatasets(state) {
			const return_datasets = [];
			for(let i = 0; i < state.powerDatasets.length; i++) {
				for(let y = 0; y < state.powerDatasets[i].types.length; y++) {
					if (state.powerDatasets[i].types[y] == `PowerTextCategoryTextTrainingDataset`) {
						return_datasets.push(state.powerDatasets[i].schema_name)
						y = state.powerDatasets[i].types.length;
					}
				}
			}
			return return_datasets;
		},
	}
};
