import Vue from "vue";
import App from "./App.vue";
import {
	Icon,
	Upload,
	Spin,
	message,
	Table,
	Button,
	Dropdown,
	Menu,
	Modal,
	TreeSelect,
	Select,
	Divider,
	Radio,
	Steps,
	Breadcrumb,
	Tabs,
	Popconfirm,
	Checkbox,
	Tree,
	Form,
	Input,
	InputNumber,
	Popover,
	Collapse,
	Switch,
	Pagination,
	Progress,
	Tooltip
} from "ant-design-vue/lib";
import "ant-design-vue/dist/antd.less";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import vuetify from "./plugins/vuetify";
import initTawk from "./plugins/tawk";

import router from "./router";
import store from "./store";
import VJstree from "vue-jstree";
import CKEditor from "@ckeditor/ckeditor5-vue";

import AsyncComputed from "vue-async-computed";
import VueDragDrop from 'vue-drag-drop';

import VueCookies from 'vue-cookies';


Vue.use(VueCookies);
Vue.$cookies.config('1d', '', import.meta.env.NODE_ENV === 'development' ? 'localhost' : '.mlc-services.com');

Vue.config.productionTip = false;

Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Popover);
Vue.use(Collapse);
Vue.use(Switch);
Vue.use(Pagination);

Vue.use(Upload);
Vue.use(Icon);
Vue.use(Spin);
Vue.use(Table);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(TreeSelect);
Vue.use(Select);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(Steps);
Vue.use(Breadcrumb);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(Popconfirm);
Vue.use(Tree);
Vue.use(Form);
Vue.use(Progress);
Vue.use(Tooltip);

Vue.use(AsyncComputed);
Vue.use(VueDragDrop);

Vue.use(VJstree);
Vue.use(CKEditor);

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;

String.prototype.trunc =
	String.prototype.trunc ||
	function (n) {
		return this.length > n ? this.substr(0, n - 1) + "..." : this;
	};

initTawk();

new Vue({
	store,
	router,
	vuetify: vuetify,
	render: h => h(App),
	created() {
		Vue.prototype.$theme = this.$vuetify.theme.themes.light;
	}
}).$mount("#app");
